.radiobutton-wrapper {
	position: relative;
	cursor: pointer;
	font-size: 14px;

	& label {
		box-sizing: border-box;
		cursor: pointer;
		padding-left: 24px;
	}
}

input[type=radio] {
	display: none;
}

input[type=radio] {
	& + label:before,
	& + label:after {
		content: "";
		display: inline-block;
		border-radius: 50%;
		transition: color ease .3s;
		position: absolute;
		box-sizing: border-box;
		top: 0;
	}

	& + label:before {
		border: 1px solid #ADB3BB;
		height: 15px;
		width: 15px;
		left: 0;
	}

	& + label:after {
		height: 9px;
		width: 9px;
		background: red;
		left: 3px;
		top: 3px;
		opacity: 0;
	}
}

input[type=radio]:checked {
	& + label:before {
		border-color: #5176A2;
	}

	& + label:after {
		background: #5176A2;
		opacity: 1;
	}
}