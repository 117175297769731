@mixin shortText($lineCount: 1) {
	display: -webkit-box;
	-webkit-line-clamp: $lineCount;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

@mixin inputActive {
	border: $border__active;
	box-shadow: $box-shadow__active;
}