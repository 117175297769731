.pagination {
	min-height: 40px;
	background: #f2f4f4;
	border-top: 1px solid #ddd;
	display: flex;
	justify-content: space-between;
	flex-direction: row-reverse;
	padding-left: 30px;

	.pagination-events {
		.mr-left {
			margin-left: 20px;
		}

		.cl-fs {
			color: #333333;
			font-size: 16px;
		}

		display: flex;
		justify-content: flex-end;
		align-items: center;
		height: 100%;

		.pagination-events_nav {
			width: 222px;
			justify-content: space-evenly;
			border-left: 1px solid #c7cfd6;
			border-right: 1px solid #c7cfd6;
			display: flex;
			height: 40px;
			align-items: center;

			button {
				display: inline-block;
				height: 39px;
				width: 39px;
				padding: 0;
				margin: 0;
				vertical-align: top;
				border: none;
				cursor: pointer;
			}

			button[disabled] {
				opacity: 0.5;
				pointer-events: none;
			}

			.pagination-events_nav_first {
				background-image: url("../assets/pagFirst/pagFirst.svg");
			}
			.pagination-events_nav_prev {
				background-image: url("../assets/pagPrev/pagPrev.svg");
			}
			.pagination-events_nav_next {
				background-image: url("../assets/pagNext/pagNext.svg");
			}
			.pagination-events_nav_last {
				background-image: url("../assets/pagLast/pagLast.svg");
			}

			span {
				color: #5176a1;
				text-decoration: underline;
				cursor: pointer;
			}
			img {
				cursor: pointer;
			}
		}
		.pagination-events_pages {
			width: 163px;
			border-right: 1px solid #c7cfd6;
			display: flex;
			height: 40px;
			align-items: center;
		}
		.pagination-events_records {
			width: 163px;
			border-right: 0px solid #c7cfd6 !important;

			border-right: 1px solid #c7cfd6;
			display: flex;
			height: 40px;
			align-items: center;
		}
	}

	& .pagination-sub_content {
		display: flex;
		align-items: center;

		& .svg-container {
			margin-left: 8px;
		}
	}
}
