@import "src/styles/base-functions";

.column-container {
	--column-gutter: 10px;
	--column-height: 21px;
	--column-row-gap: 15px;
	--container-p-y: 15px;
	--column-container--count: 2;

	padding-left: var(--column-gutter);
	padding-right: var(--column-gutter);
	width: 100%;

	& .column-container--row {
		margin-left: calc(var(--column-gutter) * -1);
		margin-right: calc(var(--column-gutter) * -1);
		display: grid;
		grid-template-columns: repeat(var(--column-container--count), 1fr);
		grid-template-rows: repeat(auto-fit, var(--column-height));
		row-gap: var(--column-row-gap);
		width: 100%;

		& .column-container--col {
			box-sizing: border-box;
			padding-left: var(--column-gutter);
			padding-right: var(--column-gutter);

			& label,
			& p,
			& span {
				display: inline-block;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100%;
			}
		}
	}


}