.input {
	margin-bottom: 15px;

	label {
		margin-bottom: 3px;
		padding: 0;
		display: block;
		font-weight: bold;
	}

	input {
		display: block;
		box-sizing: border-box;
		border: 1px solid #bebebe;
		padding: 12px;
		margin: 0 0 5px;
		width: 100%;
		outline: none;
		transition: all 300ms ease-in-out;
		border-radius: 4px;
	}

	span {
		color: #f01f30;
		font-size: 12px;
		font-weight: bold;
	}

	.invalid label {
		color: #f01f30;
	}
}


.date-picker__custom-wrapper {
	box-sizing: border-box;
	background: #ffffff;
	border: 1px solid #adb3bb;
	border-radius: 4px;
	width: 162px;
	position: relative;

	&.active {
		@include inputActive;
	}

	& .date-picker_label {
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
	}

	& span {
		margin-left: 11px;
		color: #737381;
		margin-right: 10px;
	}

	& img {
		position: relative;
		top: -2px;
		left: 8px;
	}

	& .date-picker_icon-wrapper {
		position: absolute;
		top: 0;
		right: 12px;
		height: 100%;
		display: flex;
		align-items: center;
	}

	& .date-picker_custom-popper {
		& .react-datepicker__triangle {
			top: 1px !important;
			left: 20px !important;
			transform: none !important;
			z-index: 10;
		}
	}

}

.input-text__wrapper {
	&.active {
		& input {
			@include inputActive;
		}
	}

}