.job {
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	//justify-content: space-between;

	& .main-table {
		flex-grow: 1;
	}
	thead {
		th {
			padding-top: 26px;
			padding-bottom: 10px;
		}
	}

	& .job-tooltip-container{
		width: 403px;
		height: 488px;
		top: calc(100% + 12px);
		left: calc(100% - 20px);
		position: absolute;
	}

	.job-tooltip {
		position: absolute;
		border: 1px solid #c7cfd6;
		background: #ffffff;
		box-sizing: border-box;
		border-radius: 4px;
		padding: 21px 21px 32px;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
	}

}

