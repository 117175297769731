.scroll-box {
	overflow-y: scroll;
	width: calc(100% - 15px);
}

.scroll-box::-webkit-scrollbar {
	width: 4px;
}

.scroll-box::-webkit-scrollbar-track {
}

.scroll-box::-webkit-scrollbar-thumb {
	background-color: #C7CFD6;

	-webkit-border-radius: 1px;
	-moz-border-radius: 1px;
	border-radius: 1px;
}