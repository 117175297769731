.tooltip-wrapper {
	position: relative;
	cursor: pointer;
	display: block;
	&:hover {
		& .tooltip {
			visibility: visible;
		}
	}
	& .tooltip {
		position: absolute;
		padding: 16px 18px;
		background-color: #fff;
		border: 1px solid #DCE1E5;
		box-sizing: border-box;
		box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.15);
		border-radius: 6px;
		visibility: hidden;
		z-index: 100;
		min-width: 403px;


		&.right {
			top: 0;
			left: 32px;
		}
	}
}