@import "./base-functions";
@import "./_cycles.scss";
@import "Input.scss";
@import "Select.scss";
@import "Pagination.scss";
@import "Backdrop.scss";
@import "ModalTestCreate.scss";
@import "ModalRegistration.scss";
@import "Loader.scss";
@import "Jobs.scss";
@import "Filter.scss";
@import "Table.scss";

@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,500,700,900");

#root {
	height: 100vh;
	display: flex;
	overflow: hidden;
}

::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-track {
	//background-color: gray;
}

::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.2);
	-webkit-border-radius: 6px;
	-moz-border-radius: 6px;
	border-radius: 6px;
}

html {
	font-family: "Rubik", sans-serif !important;
	font-size: $font-size_base + px;
	line-height: 1.1875;
}

body {
	font-family: "Rubik", sans-serif !important;
	margin: 0;
	padding: 0;
	font-size: 1rem;
}



.container {
	color: rgb(31, 73, 113);
	width: 100%;

	h1 {
		text-align: center;
		color: rgb(31, 73, 113);
		margin-top: 100px;
	}

	form {
		p {
			margin: 10px;
		}
	}

	.user__list {
		display: flex;
		flex-wrap: wrap;
		padding: 0;
		list-style: none;
		justify-content: flex-start;
		margin: 0 auto;
		width: 85%;
		margin-bottom: 60px;

		&-item {
			background: aliceblue;
			height: 115px;
			width: 245px;
			border-radius: 5px;
			margin-right: 25px;
			margin-top: 25px;
			display: flex;
			cursor: pointer;
			box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.35);

			.avatar {
				width: 40%;
				padding-top: 20px;
				padding-left: 10px;

				img {
					width: 80%;
					border-radius: 50%;
				}
			}
			.description {
				padding-top: 30px;
				padding-left: 10px;
				color: rgb(31, 73, 113);
				font-size: 17px;

				span {
					display: block;
					margin-bottom: 2px;
				}
			}
		}
		&-item:hover {
			background: #e2e8ec;
		}
	}

	.wrap {
		margin: 0 auto;
		width: 100%;
		max-width: 600px;
		padding: 0 20px;

		form {
			color: rgb(31, 73, 113);
			background: #eee;
			border-radius: 5px;
			padding: 15px;
			box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
		}

		.activequiz {
			padding: 20px;
			color: rgb(31, 73, 113);
			border: 2px solid rgb(31, 73, 113);
			border-radius: 5px;
			margin: 0 10px;
			box-sizing: border-box;

			.activequiz__answerlist {
				list-style: none;
				margin: 0;
				padding: 0;

				&-item {
					border: 1px solid rgb(31, 73, 113);
					border-radius: 5px;
					padding: 5px 10px;
					margin-bottom: 5px;
					cursor: pointer;
				}
				&-item:hover {
					background: rgba(41, 119, 193, 0.9);
					color: #fff;
					transition: background 0.3s ease-in-out;
				}
				.success {
					background: rgba(161, 240, 69, 0.7);
				}
				.error {
					background: rgba(240, 87, 108, 0.7);
				}
			}
		}

		.question {
			display: flex;
			justify-content: space-between;
		}

		.finishedquiz {
			padding: 20px;
			color: rgb(31, 73, 113);
			border: 2px solid #fff;
			border-radius: 5px;
			box-sizing: border-box;
			margin: 0 10px;

			ul {
				list-style: none;
				padding: 0;
				margin: 0;

				li i {
					margin-left: 10px;
				}
				.success {
					color: rgba(161, 240, 69, 0.7) !important;
					background: none;
				}

				.error {
					color: rgba(240, 87, 108, 0.7) !important;
					background: none;
				}
			}
		}
	}
}

.auth {
	width: 100vw;
	height: 100vh;
	background: #50749d;
	position: absolute;

	h1 {
		color: rgb(31, 73, 113);
		text-align: center;
	}

	div {
		width: 100%;
		max-width: 373px;
		color: rgb(31, 73, 113);
	}

	form {
		background: #eee;
		box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
		border-radius: 5px;
		padding: 20px 35px 35px;
	}

	button {
		width: 100%;
		background-color: #5176a2;
		color: #eee;
		padding: 15px 20px;
		font-weight: 200;
	}

	label {
		font-weight: 200;
		color: #737381;
	}
}

.auth-wrap {
	width: 373px;
	height: 491px;
	margin: 209px auto 0;
}




img[disabled] {
	opacity: 0.5;
	pointer-events: none;
}

.dropdown {
	margin-bottom: 20px;
}

.dropzone:focus {
	outline: none;
}

.container:focus {
	outline: none;
}

form:focus {
	outline: none;
}

.css-tlfecz-indicatorContainer {
	color: #5176a1 !important;
}

.css-1okebmr-indicatorSeparator {
	width: 0px !important;
}

.filter-name-doc {
	font-size: 16px;
	line-height: 19px;
	color: #5176a2;
	letter-spacing: 0.01em;
	border: none;
	position: relative;
	top: -3px;
	width: 212px;

	&::placeholder {
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		color: #5176a2;
	}

	&:focus {
		outline: #5176a2 !important;
	}
}

.filter-block {
	padding: 16px;
	padding-left: 30px;
	color: #5176a1;
	font-weight: 600;
	display: flex;
	align-items: center;

	.params {
		cursor: pointer;
	}

	.params::after {
		content: "";
		width: 8px;
		height: 5px;
		background: url("../assets/downIcon/downIcon.svg") no-repeat;
		display: inline-block;
		position: relative;
		left: 7px;
		top: -3px;
	}
}

.filter-tooltip {
	width: 403px;
	height: 468px;
	background: #ffffff;
	border: 1px solid #c7cfd6;
	box-sizing: border-box;
	border-radius: 4px;
	z-index: 99;
	position: absolute;
	left: 175px;
	top: 115px;
	padding: 21px;

	.top {
		margin-top: 10px;
	}

	.wrap-datepic,
	.wrap-typeprocess,
	.wrap-event,
	.wrap-idtask {
		clear: both;
		margin-bottom: 16px;
	}

	label {
		font-family: Rubik;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 19px;
		letter-spacing: 0.01em;
		color: #737381;
	}
}

.react-datepicker__input-container {
	width: 0;
	input {
		width: 162px;
		height: 42px;
		background: #ffffff;
		border: 1px solid #adb3bb;
		box-sizing: border-box;
		border-radius: 4px;
	}
	input:focus-visible {
		outline: 0px;
	}
}

.wrap-datepic {
	.wrap-datepic_item {
		display: flex;
		justify-content: space-between;
	}
}

.wrap-idtask {
	input[type="text"] {
		width: 100%;
		height: 42px;
		background: #ffffff;
		border: 1px solid #adb3bb;
		box-sizing: border-box;
		border-radius: 4px;
	}
	input[type="text"]:focus-visible {
		outline: 0px;
	}
}

.wrap-find {
	margin-top: 29px;
	display: flex;
	justify-content: center;

	input[type="submit"] {
		width: 194px;
		height: 50px;
		color: white;
		background: #5176a1;
		border-radius: 4px;
		font-style: normal;
		font-weight: normal;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
		border: 1px solid #bebebe;
		cursor: pointer;
	}
}

.main-wrap {
	height: calc(100% - 0px);
}

.journal {
	height: 100%;
}

.chips {
	margin-left: 15px;
	display: flex;

	.chip-item {
		background: #dae2ea;
		border-radius: 3px;
		box-sizing: border-box;
		font-size: px(13);
		line-height: px(15.41px);
		color: #406187;
		display: flex;
		align-items: center;
		justify-content: space-around;
		margin-right: 17px;
		padding: 4px 6px 4px 10px;
		height: 30px;

		& .chip-item_icon-wrapper {
			margin-left: 3px;
			cursor: pointer;
			transition: all .2s ease;
			max-width: 22px;
			max-height: 21px;
			&:hover {
				opacity: .5;
			}
		}
	}
}

.css-1uccc91-singleValue {
	color: black !important;
}

.react-datepicker__input-container input {
	border: 0px solid #adb3bb !important;
	width: 90px !important;
	color: black !important;
	font-size: 17px !important;
}

.arrow-container {
	position: absolute;
	bottom: calc(100% + 3px);
	left: 14px;
	z-index: 1;
}
.arrow {
	width: 0;
	height: 0;
	border-radius: 50px;
	display: inline-block;
	position: absolute;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	top: 50%;
	left: 50%;
}

.arrow:after {
	content: "";
	width: 0;
	height: 0;
	position: absolute;
}

.arrow-bottom {
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 8px solid #c7cfd6;
}
.arrow-bottom:after {
	top: 1px;
	border-left: 8px solid transparent;
	border-right: 8px solid transparent;
	border-bottom: 8px solid #fff;
	left: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] {
	z-index: 2 !important;
}

.css-ig5d4d-menu {
	z-index: 2 !important;
}

.css-1bi0jn-control {
	min-height: 44px !important;
}

.css-1o3px96-option,
.css-15uxb3p-option {
	background-color: white !important;
	color: #50749d !important;
}

.css-ig5d4d-menu {
	z-index: 2 !important;
}

.w-100 {
	width: 100%;
}

.h-100 {
	height: 100%;
}

.max-h-100 {
	max-height: 100%;
}

.max-w-100 {
	max-width: 100%;
}

.flex-grow-1 {
	flex-grow: 1;
}

.short-text-1 {
	word-break: break-word;
	@include shortText
}

.make-hover {
	transition: opacity .2s ease;
	&:hover {
		opacity: .7;
	}
}

.has-cursor {
	cursor: pointer;
}