$header-gutter: 30px;
.main-header {
	grid-area: layout-header;
	display: flex;
	align-items: center;
	min-height: 70px;
	max-height: 70px;
	width: calc(100% - #{$header-gutter * 2});
	background: #f2f4f4;
	border-bottom: 1px #c7cfd6 solid;
	justify-content: space-between;
	position: sticky;
	top: 0;
	padding-left: $header-gutter;
	padding-right: $header-gutter;

	.breadcrumb {
		font-size: 22px;
		line-height: 26px;
	}
	.avatar {
		display: flex;
		align-items: center;
		height: 60px;

		& > span {
			margin-right: 20px;
		}
		.sub-menu {
			display: none;
			& .sub-menu-wrapper {
				padding: 1px;
				background: #50749d;
				border-radius: 4px;
				filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
			}
			.sub-menu-content {
				color: #fff;
				border-radius: 4px;
				padding: 10px 10px;
				width: 145px;
				display: flex;
				align-items: center;
				font-size: 15px;
				cursor: pointer;
				position: relative;
				margin-right: 0 !important;
				&:hover {
					background: #8399B4;
					&:not(:last-child):after {
						--sub-menu__sub-border-width: 145px;
						position: absolute;
						content: "";
						display: block;
						background: #8399B4;
						width: var(--sub-menu__sub-border-width);
						left: calc(50% - (var(--sub-menu__sub-border-width) / 2));
						height: 1px;
						top: calc(100% + 1px);
						z-index: 100;
					}

					&:not(:last-child):after {
						top: calc(100% + 1px);
					}
					&:last-child:before {
						bottom: calc(100% + 1px);
					}
				}
			}
			img {
				margin-right: 10px;
			}
		}
		.settings.active {
			.sub-menu {
				display: block;
				position: absolute;
				top: 38px;
				right: 64px;
				padding-top: 25px;
			}
			.triangle {
				top: 18px;
				right: 0px;
				position: absolute;
				width: 0;
				height: 0;
				border-bottom: 10px solid #50749d;
				border-left: 7px solid transparent;
			}
		}
	}
}