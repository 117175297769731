.drawer {
	grid-area: layout-drawer;
	height: 100vh;
	box-sizing: border-box;
	z-index: 10;
	background: #f2f4f4;
	overflow: hidden;
	border-right: 1px #c7cfd6 solid;
	display: flex;
	flex-direction: column;
	padding-bottom: 10px;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		li {
			display: flex;
			align-items: center;
			margin-left: 0px;
			height: 46px;
			a {
				height: 100%;
				width: 100%;
				color: #5176a1;
				text-decoration: none;
				position: relative;
				font-style: normal;
				font-weight: normal;
				font-size: 15px;
				line-height: 18px;
				display: flex;
				align-items: center;
				padding: 0 10px 0 29px;
			}
			a {
				&:not(.active) {
					&:hover {
						opacity: 0.7;
					}
				}
			}
			.active {
				opacity: 1;
				background: #e2ecff;
			}
			.active::after {
				content: "";
				background: #5176a1;
				position: absolute;
				bottom: 0px;
				left: 256px;
				height: 100%;
				width: 2.5px;
			}
		}
	}

	& .drawer_version-wrapper {
		margin-top: auto;
		text-align: center;
		color: #ADB3BB;
		font-weight: 300;
		font-size: 14px;
		line-height: 17px;
		text-decoration-line: underline;
	}
}

$nav-header-offset: 20px;
.nav-header {
	padding-top: 18px;
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: 11px;

	&:after {
		position: absolute;
		top: 100%;
		content: "";
		height: 1px;
		left: $nav-header-offset;
		width: calc(100% - #{$nav-header-offset} * 2);
		display: block;
		background: #c7cfd6;
	}

	span {
		color: #5176a1;
		font-size: 11px;
		font-weight: 100;
	}

	& img {
		display: inline-block;
	}



	&:not(.narrow) {
		padding-left: 27px;
		padding-bottom: 15px;

		& img {
			margin-right: 32px;
		}
	}

	&.narrow {
		flex-direction: column;
		padding-bottom: 15px;

		& img {
			margin-bottom: 16px;
		}
	}
}


.nav-header-li-item {
	span {
		margin-left: 18px;
	}

	& .svg-container {
		width: 24px;
		height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.close {
	.nav-header-li-item {
		a {
			padding-left: 0;
		}
	}

	ul {
		li {
			padding-left: 0px;
			.active::after {
				content: "";
				background: #5176a1;
				position: absolute;
				bottom: 0px;
				left: 96px;
				height: 100%;
				width: 1.5px;
			}
			a {
				padding: 0;
				& img,
				& .svg-container, {
					padding-left: 37px;
				}
			}
		}
	}
	.nav-header-li-item {
		span {
			display: none;
		}
	}
}
