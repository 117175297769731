@function setColor($var) {
	@return var(--#{$var}, red);
}

@function getColor($value) {
	@if(map-has-key($base-colors, $value)) {
		@return setColor($value)
	} @else {
		@error "Нет такого значения";
	}
}


@function px($px) {
	@return unquote(#{$px / $font-size_base}) + rem;
}

@function em($px) {
	@return unquote(#{$px / $font-size_base}) + em;
}
