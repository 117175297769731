@import "../../styles/functions";

.contacts-container {
	display: flex;

	& .contacts-column {
		flex-basis: 50%;

		&.contacts-column_left {

		}

		&.contacts-column_right {
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;

			& a {
				color: setColor("active");
				margin-bottom: 6px;
			}
		}
	}

	& p {
		margin: 0;
	}
}