@import "src/styles/base-functions";

.investigation {
	display: flex;
	flex-grow: 1;

	.investigation-event {
		flex-basis: 55%;
		border-right: 1px solid getColor("grey-dark");
		padding: 23px 23px 40px;
		display: flex;

		& form {
			display: inline-flex;
			flex-direction: column;
		}

		& section {
			display: flex;
			flex-direction: column;
		}

		aside > p {
			letter-spacing: 0.01em;
			margin-top: 17px;
			margin-bottom: 37px;
			margin-left: 15px;
		}
	}

	.investigation-result {
		flex-basis: 45%;
		height: 100%;

		.investigation-result_header {
			font-style: normal;
			font-weight: 500;
			border-bottom: 1px #c7cfd6 solid;
			padding: 15px 16px 15px 15px;
			color: #5176a2;
			letter-spacing: 0.01em;
		}

		.investigation-result_body {
			& table {

				width: 100%;

				th,
				td {
					text-align: left;
					border-bottom: 1px solid #ddd;
					border-right: 1px solid #ddd;
					font-weight: normal;
					font-size: px(15);
					padding: 10px;
					letter-spacing: 0.01em;
					font-style: normal;
					color: #222222;
				}

				& tr {

					&:nth-child(even) {
						background: #ffffff;
						//max-width: 50%;
						min-width: 30%;
					}

					& th {
						width: 50%;
					}
				}
			}
		}
	}
}

.invest-res {
	margin-top: 46px;
	display: flex;
	justify-content: center;

	span {
		display: flex;
		font-style: normal;
		color: getColor("dark-light");
		text-align: center;

		&:not(.empty-result) {
			width: 280px;
		}
	}
}