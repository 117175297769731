@import "src/styles/base-functions";

.chart-table__container {
	font-size: 14px;
}

.chart-table__row-body {
	max-height: 228px;
}

.chart-table__row {
	display: grid;
	grid-template-columns: 220px 100px 110px;
	column-gap: 16px;

	&:not(:last-child) {
		margin-bottom: 12px;
	}

	& .color-box {
		margin-right: 8px;
	}
}


.chart-table__row-headers {
	margin-bottom: 10px !important;
	color: getColor("dark-light");
	width: calc(100% - 19px);
}

.chart-table__column {
	display: flex;
	align-items: center;

	&.chart-table__label {
		& .short-text {
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&.chart-table__size {
		margin-right: 10px;
	}
}
