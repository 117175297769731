$default-triangle-gutter: 4px;

.white-tooltip__wrapper {
	position: relative;


	& .tooltip {
		min-width: 415px;
		position: absolute;
		top: calc(100% + 3px);
		z-index: 100;
		background: #FFFFFF;
		border: 1px solid #C7CFD6;
		box-sizing: border-box;
		border-radius: 4px;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
		visibility: hidden;
		transition: visibility, opacity .2s ease;
		opacity: 0;

		&.visible {
			visibility: visible;
			opacity: 1;
		}

		&.position-right {
			right: calc(#{$default-triangle-gutter} * -1);

			& .tooltip-inner {
				&:before,
				&:after {
					right: #{$default-triangle-gutter + 2};
				}
			}

		}

		&.position-left {
			left: calc(#{$default-triangle-gutter} * -1);

			& .tooltip-inner {
				&:before,
				&:after {
					left: $default-triangle-gutter;
				}
			}
		}

		& .tooltip-inner {
			padding: 16px;
			position: relative;
			&:before,
			&:after {
				content:"";
				position:absolute;
				border-bottom: 8px solid #FFFFFF;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
			}

			&:before {
				z-index: 1;
				top: -7px;
			}

			&:after {
				top: -9px;
				border-bottom-color:#c7cfd6;
			}
		}


	}
}