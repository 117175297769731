.layout {
	--menu-size: 100px;
	width: 100%;
	display: grid;
	grid-template-areas:
			"layout-drawer layout-header"
			"layout-drawer layout-main";
	grid-template-columns: var(--menu-size) 1fr;
	grid-template-rows: 70px 1fr;
	//overflow: hidden;

	&.has-open-menu {
		--menu-size: 260px;
	}

	& main {
		grid-area: layout-main;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		z-index: 10;
		overflow-y: auto;
		//overflow-x: hidden;
	}

}

