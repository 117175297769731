.settings-wrapper {
	padding: 20px 15px;
	display: flex;
	flex-wrap: wrap;
	gap: 16px;
}

.notifications-setting__container {
	width: 400px;

	& .notifications-setting__list-label {
		margin: 0 0 13px;
		color: #737381
	}

	& .notifications-setting__list-item {
		&:not(:last-child) {
			margin-bottom: 2rem;
		}
	}

	& .checkbox-wrapper {
		&:not(:last-child) {
			margin-bottom: 6px;
		}
	}

	& .btn-wrapper {
		margin-top: 32px;
	}
}