@import "src/styles/base-functions";


.report-container {
	padding: 20px 14px;
	display: grid;
	gap: 16px;
	grid-template-columns: repeat(2, 1fr);
	letter-spacing: 0.01rem;
}

.report-chart__data {
	width: 100%;
	display: flex;
	padding-left: 15px;
}

.report-chart__column {
	&:nth-child(1) {
		max-width: 33%;
		flex-grow: 1;
	}

	&:nth-child(2) {
		flex-grow: 1;
		padding-left: 33px;
	}

	& .report-chart {
		width: 100%;
	}
}
