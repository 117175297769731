.license-card {
	& p {
		margin: 0 0 12px;
	}
	& ul {
		list-style: none;
		padding: 0;
		margin: 0;
		&:not(:last-child) {
			margin-bottom: 8px;
		}

		& li {
			margin: 0;
		}
	}
}