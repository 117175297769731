.report-chart {
	position: relative;

	& .information-box {
		position: absolute;
		top: calc(25% + 5.5px);
		left: 25%;
		width: 50%;
		height: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		user-select: none;

		& p {
			&:not(:last-child) {
				margin: 0 0 2px;
			}
			&:last-child {
				margin: 0;
			}

			&.information-box__label {
				font-size: 22px;
			}
		}
	}
}