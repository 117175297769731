.modal-container {
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.4);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	pointer-events: none;
	transition: 0.3s;

	&.active {
		opacity: 1;
		pointer-events: all;
	}

	& .modal-inner {
		background: #ffffff;
		border: 1px solid #c7cfd6;
		box-sizing: border-box;
		border-radius: 10px;
		padding: 20px 24px;
		position: relative;
		width: 531px;
	}

	& .modal-header {
		display: flex;
		align-items: center;
		margin-bottom: 15px;
		font-size: 23px;
		line-height: 27px;
		color: #5176A2;

		& .modal-header__icon-wrapper {
			margin-right: 14px;
			height: 23px;
		}
	}

	& .modal-body {}

	& .button {
		position: absolute;
		top: 16px;
		right: 16px;
		cursor: pointer;
		border: none;
	}
}


