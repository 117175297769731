:root {
	@each $color_name, $color in $base-colors {
		--#{$color_name}: #{$color};
	}
}

@each $color_name, $color in $base-colors {
	.text-color_#{$color_name} {
		color: #{setColor($color_name)};
	}

	.bg-color_#{$color_name} {
		background-color: #{setColor($color_name)};
	}

}

@each $position in $text-position {
	.text-#{$position} {
		text-align: #{$position};
	}
}