.information-block__header {
	display: flex;
	justify-content: space-between;
	& p {
		display: inline-block;
		margin: 0 0 16px;
	}

	& .information-block__settings {
		top: 0;
		right: 0;
		cursor: pointer;
	}
}

.information-block__body {}