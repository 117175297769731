.filter-params {
	border-bottom: 1px #c7cfd6 solid;
	height: 51px;
	display: flex;
	align-items: center;
	.filter-params_event {
		font-size: 16px;
		line-height: 19px;
		color: #5176a1;
		margin-left: 30px;
		cursor: pointer;
		position: relative;
		display: flex;
		align-items: center;

		& .filter-params_event-label {
			font-weight: 600;
		}
	}

	& .icon-wrapper {
		margin-left: 5px;
		display: flex;
		height: 100%;
		align-items: center;
	}
}

.dropdown-text__wrapper,
.input-text__wrapper {
	height: 60px;
	position: relative;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;

	& label {
		font-weight: 400;
		color: #737381;
		position: absolute;
		cursor: pointer;
		pointer-events: none;
	}

	& .label-inside {
		bottom: 11px;
		left: 10px;
		font-size: 16px;
		line-height: 19px;
		transition: 3ms;
	}

	& .label-outside {
		top: 0px;
		left: 0px;
		font-size: 12px;
		line-height: 14.22px;
		transition: 3ms;
	}

	& .label-dropdown {
		z-index: 1;
	}
}

.input-text__wrapper {
	input {
		background: #ffffff;
		border: 1px solid #adb3bb;
		box-sizing: border-box;
		min-height: 42px !important;
		border-radius: 4px;
		width: 361px;
		height: 44px;
		padding-left: 10px;
		color: black;
		font-size: 17px;
		opacity: 1;
	}
}

.filter-form {
	//padding-top: 25px;
	& .dropdown {
		margin-bottom: 0;
	}

	& label {
		font-weight: 400;
		color: #737381;
	}

	& .filter-form_element {
		&:not(:last-child) {
			margin-bottom: 14px;
		}
		&:last-child {
			margin-top: 30px;
		}

		& .label-wrapper {
			margin-bottom: 10px;
		}
	}

	.filter-form_error {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: 10px;
		margin-bottom: -15px;
		color: red;
		font-size: 15px;
	}

	.filter-form_line {
		display: block;
		width: 26px;
		border-top: 2px solid rgb(204, 204, 204);
		margin-top: 22px;
		margin-left: 5px;
		margin-right: 5px;
	}

	.filter-submit-wrap {
		display: flex;
		justify-content: center;
		input {
			background: #5176a1;
			border-radius: 4px;
			height: 50px;
			width: 194px;
			color: white;
			font-size: 20px;
			box-shadow: none;
			border-color: none;
			border-style: hidden;
			cursor: pointer;
		}
	}
	:focus-visible {
		outline: none;
	}


}
