$color__active: #C0D9FF;
$font-size_base: 16;

$border__active: 1px solid $color__active;
$box-shadow__active: 0 0 0 1px $color__active;

$base-colors: (
	"dark": "#222",
	"primary": "#5176A1",
	"active": "#003ACE",
	"accent": "#c7cfd6",
	"dark-light": "#737381",
	"grey-light": "#F2F4F4",
	"grey-dark": "#c7cfd6",
	"dark-blue": "#50749D",
);

$text-position: "left", "right", "center";