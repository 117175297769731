
.main-table {
	display: flex;
	flex-direction: column;
	max-height: 100%;
	flex-grow: 1;
	&.has-pagination {
		overflow: hidden;
		& * {
			box-sizing: border-box;
		}
	}
	&:not(.has-pagination) {
		& .main-table_footer {
			position: sticky;
			bottom: 0;
		}
	}
	.main-table_body {
		flex: 1 0 auto;
	}


}

.main-table_body {
	flex-grow: 1;

	table {
		border-collapse: collapse;
		width: 100%;
		margin: 0 auto;
		color: rgb(31, 73, 113);
		table-layout: fixed;
		border: none;

		& thead {
			border-bottom: 1px #737381 solid;

			& tr {
				height: 76px !important;
				border-bottom: 1px solid #ddd;

				& th {
					border-right: 0px;
					font-weight: 600;
					font-size: 16px;
					line-height: 19px;
					padding: 20px;
					color: #5176a2;
					letter-spacing: 0.01em;
				}
			}
		}

		tbody th,
		tbody td {
			text-align: left;
			border-bottom: 1px solid #ddd;
			font-weight: 400;
			font-size: 14px;
			color: #222222;
			letter-spacing: 0.01em;
		}

		& .tbody {
			overflow-x: auto;
			height: 100%;
			width: 100%;
		}

		tbody {


			& tr:nth-child(odd) {
				background: #ffffff;
			}

			& tr:nth-child(even) {
				background: #f2f4f4;
			}
		}

		th,
		td {

			text-align: left;
			border-bottom: 1px solid #ddd;
			font-weight: normal;
			font-size: 17px;
			padding-right: 12px;
			box-sizing: border-box;

			&:not(:first-child) {
				padding-left: 12px;
			}

			&:first-child {
				padding-left: 30px;
			}
		}
	}


}