.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  //	background-color: rgba(0,0,0,0.3);
  padding: 50px;

  .modal {
    background: #eee;
    -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    padding: 15px;
    border-radius: 5px;
    max-width: 500px;
    min-height: 300px;
    margin: 0 auto;
  }

  h1 {
    color: #b7abab;
    text-align: center;
  }

  ul {
    margin-bottom: 10px;

    li {
      list-style: none;
      margin-left: -30px;
      color: cadetblue;
      margin-bottom: 4px;
    }
  }
}
