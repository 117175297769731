$base-border-params: 1px solid #ddd;


@mixin TableItem {
	padding: 7px 8px 8px 8px;
	font-size: 14px;
	line-height: 19.5px;
	font-weight: 400;
	text-align: left;
	display: flex;
	flex: 1 0 50%;

	& span {
		max-width: 100%;
		word-break: break-word;
	}
}

.table__width-grid {
	width: 100%;

	& * {
		box-sizing: border-box;
	}

	&.table__width-number {
		& .table__width-number__header-row,
		& .table__width-number__body-row {
			display: grid;
			grid-template-columns: 36px 1fr;
		}

		& .table__width-number__body-row {
			border-top: $base-border-params;
		}


		& .table__width-number__column--right {
			& .table__width-number__item {
				@include TableItem;

				&:not(:first-child) {
					border-left: $base-border-params;
				}
			}
		}
	}

	&.table__width-parts {
		& .table__width-number__header-row,
		& .table__width-number__body-row {
			display: grid;
			grid-template-columns: repeat(1, 1fr);
		}

		& .table__width-number__item {
			@include TableItem;

			&:not(:first-child) {
				border-left: $base-border-params;
			}
		}
	}

	& .table__width-number__item-left {
		& span {
			@include shortText;
		}
	}

	& .table__width-number__header {
		& .table__width-number__column {
			display: flex;
			align-items: center;
		}

		& .table__width-number__column--left {
			justify-content: center;
			min-height: 50px;
			align-items: center;
		}

		& .table__width-number__column--right {
			& .table__width-number__item {
				padding-left: 10px;
			}

			& .item-index__label {
				letter-spacing: 0.02em;
				font-weight: 600;
				font-size: 16px;
			}
		}

		& .item-index__label {
			font-size: 16px;
			line-height: 19px;
			font-weight: 600;
		}
	}

	& .table__column-row {
		width: 100%;
		display: flex;

		&.table__width-number__column-row {
			&:not(:first-child) {
				border-top: $base-border-params;
			}
		}

		&.table__width-parts__column-row {
			&:not(:last-child) {
				border-bottom: $base-border-params;
			}

			& .table__width-number__item {
				flex-basis: 50%;
			}
		}
	}

	& .table__width-number__column {
		height: 100%;
	}


	& .item-index__label {
		color: #5176a2;
	}

	& .table__width-number__column--right {
		border-left: $base-border-params;
	}

	& .table__width-number__body-row {

		& .table__width-number__column--left {
			display: flex;
			justify-content: center;
			padding-top: 6px;
		}


		& .item-index__label {
			font-size: 20px;
			line-height: 23.7px;
			font-weight: 400;
		}
	}
}

