.button {
	display: inline-block;
	padding: 10px 20px;
	border-radius: 4px;
	border: none;
	color: #000;
	font-size: 14px;
	font-family: inherit;
	line-height: inherit;
	transition: opacity .2s ease;

	&:not(.not-hover) {
		&:hover {
			opacity: .7;
		}
	}


	&:focus {
		outline: none;
	}

	&:not(.transparent) {
		&:active {
			box-shadow: inset 2px 2px 1px rgba(0, 0, 0, 0.3);
		}
	}

	&:not(:disabled) {
		cursor: pointer;
	}

	&:disabled {
		background: #adb3bb;
		cursor: not-allowed;
		color: #fff;
	}
}

.error {
	background: rgba(240, 87, 108, 1);
}

.success {
	background: rgba(161, 240, 69, 1);
	color: #fff !important;
	cursor: pointer;
}

.primary {
	background: #5176a1;
	color: #fff;
	font-size: 22px;
	padding: 12px 54px;
}

.submit {
	background: #5176a1;
	color: #fff;
	width: 230px;
	cursor: pointer;
}

.transparent {
	border: none;
	background-color: transparent;
	outline: none;
	box-shadow: none;
	padding: 0 !important;
	margin: 0;
}

.btn-wrapper {
	& > button {
		&:not(:last-child) {
			margin-right: 1rem;
		}
	}
}